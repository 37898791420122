.white-text {
    color: white !important;
}

.form-control:focus {
    color: white !important;
}

.form-control {
    color:white !important;
}

.form-control::placeholder {
    color: white !important;
}

.form-control:-ms-input-placeholder { 
/* Internet Explorer 10-11 */
    color: white !important;
}

.form-control::-ms-input-placeholder {
/* Microsoft Edge */
    color: white !important;
}

.aria-text {
    color: white;
    font-size: 12px;
    line-height: 1 !important;
}

.modal-footer {
    border-top: 0 none !important;
    padding-top: 0;
}

